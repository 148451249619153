import { isNil, isNotNil } from "./typeguards"

type MatchMqttTopicResult =
  | {
      matches: true
      wildcardSegments: string[]
    }
  | { matches: false }

export function matchMqttTopic(topic: string, wildcard: string): MatchMqttTopicResult {
  const topicLevels = topic.split("/")
  const wildcardLevels = wildcard.split("/")

  const wildcardSegments: string[] = []

  for (let i = 0; i < wildcardLevels.length; i++) {
    const wildcardSegment = wildcardLevels.at(i)
    const topicSegment = topicLevels.at(i)

    if (isNil(topicSegment) || isNil(wildcardSegment)) {
      break
    }

    if (wildcardSegment === "#") {
      wildcardSegments.push(...topicLevels.slice(i))
      return { matches: true, wildcardSegments }
    }

    if (wildcardSegment === "+") {
      if (isNotNil(topicSegment)) {
        wildcardSegments.push(topicSegment)
      }

      continue
    }

    if (wildcardSegment === topicSegment) {
      continue
    }

    // topic are not matching at all - returning undefined
    return { matches: false }
  }

  return topicLevels.length === wildcardLevels.length ? { wildcardSegments, matches: true } : { matches: false }
}
