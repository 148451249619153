import { isNil } from "./typeguards"

export function calculateEMA(inputs: number[], period: number): number[] | undefined {
  if (inputs.length < period) {
    return
  }

  if (inputs.some((v) => Number.isNaN(v))) {
    return
  }

  const alpha = 2 / (period + 1)
  const ema: number[] = []

  // Initialize the first EMA value as the Simple Moving Average (SMA) of the first N values.
  const sma = inputs.slice(0, period).reduce((sum, price) => sum + price, 0) / period
  ema.push(sma)

  // Calculate EMA for rest of the values
  for (let i = period; i < inputs.length; i++) {
    const prevEma = ema[i - period]
    const price = inputs[i]

    if (isNil(prevEma) || isNil(price)) {
      continue
    }

    const emaValue = price * alpha + prevEma * (1 - alpha)
    ema.push(emaValue)
  }

  return ema
}
