import type { Primitive } from "type-fest"

import { invariant, isNotNil } from "./typeguards"
import { CamelCaseToSnakeCase, Ensure, SnakeCaseToCamelCase } from "./types"

export function ensureKeys<TObject, TKey extends keyof TObject>(
  input: TObject,
  keys: TKey | TKey[],
): Ensure<TObject, TKey> {
  for (const key of Array.isArray(keys) ? keys : [keys]) {
    invariant(isNotNil(input[key]), `${key.toString()} is nil.`)
  }

  return { ...input } as Ensure<TObject, TKey>
}

export function copyKeysToValues<TKey extends string | number | symbol>(
  record: Record<TKey, unknown>,
): Record<TKey, TKey> {
  return Object.fromEntries(Object.keys(record).map((key) => [key, key])) as Record<TKey, TKey>
}

// taken from https://github.com/sindresorhus/ts-extras/blob/main/source/object-keys.ts
export type ObjectKeys<T extends object> = `${Exclude<keyof T, symbol>}`

export const objectKeys = Object.keys as <Type extends object>(value: Type) => Array<ObjectKeys<Type>>

// taken from https://github.com/sindresorhus/ts-extras/blob/main/source/object-entries.ts
export const objectEntries = Object.entries as <Type extends Record<PropertyKey, unknown>>(
  value: Type,
) => Array<[ObjectKeys<Type>, Required<Type>[ObjectKeys<Type>]]>

// taken from https://github.com/sindresorhus/ts-extras/blob/main/source/object-from-entries.ts
export const objectFromEntries = Object.fromEntries as <
  Key extends PropertyKey,
  Entries extends ReadonlyArray<readonly [Key, unknown]>,
>(
  values: Entries,
) => {
  [K in Extract<Entries[number], readonly [Key, unknown]>[0]]: Extract<Entries[number], readonly [K, unknown]>[1]
}

export type NullToUndefined<T> = T extends null
  ? undefined
  : // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    T extends Primitive | Function | Date | RegExp
    ? T
    : T extends (infer U)[]
      ? NullToUndefined<U>[]
      : T extends Map<infer K, infer V>
        ? Map<K, NullToUndefined<V>>
        : T extends Set<infer U>
          ? Set<NullToUndefined<U>>
          : T extends object
            ? { [K in keyof T]: NullToUndefined<T[K]> }
            : unknown

function _nullToUndefined<T>(obj: T): NullToUndefined<T> {
  if (obj === null) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return undefined as any
  }

  if (typeof obj === "object") {
    if (obj instanceof Map) {
      obj.forEach((value, key) => obj.set(key, _nullToUndefined(value)))
    } else {
      for (const key in obj) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        obj[key] = _nullToUndefined(obj[key]) as any
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return obj as any
}

/**
 * Recursively converts all null values to undefined.
 *
 * @param obj object to convert
 * @returns a copy of the object with all its null values converted to undefined
 */
export function nullToUndefined<T>(obj: T) {
  return _nullToUndefined(structuredClone(obj))
}

type GetReturnType<T> = T | undefined
type ValueType = Record<string | number, unknown>

export function getProperty<T>(
  value: unknown,
  query: string | Array<string | number>,
  defaultVal: GetReturnType<T> = undefined,
): GetReturnType<T> {
  const splitQuery = Array.isArray(query)
    ? query
    : query
        .replace(/(\[(\d)])/g, ".$2")
        .replace(/^\./, "")
        .split(".")

  if (splitQuery.length === 0 || splitQuery[0] === undefined) {
    return value as T
  }

  const key = splitQuery[0]
  if (typeof value !== "object" || value === null || !(key in value) || (value as ValueType)[key] === undefined) {
    return defaultVal
  }

  return getProperty((value as ValueType)[key], splitQuery.slice(1), defaultVal)
}

//
//
//

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function snakeCaseToCamelCase<T extends Record<string, any>>(obj: T): SnakeCaseToCamelCase<T> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      const camelKey = key.replace(/_([a-z])/g, (_, char) => char.toUpperCase())
      return [camelKey, value]
    }),
  ) as SnakeCaseToCamelCase<T>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function camelCaseToSnakeCase<T extends Record<string, any>>(obj: T): CamelCaseToSnakeCase<T> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      const snakeKey = key.replace(/([A-Z])/g, "_$1").toLowerCase()
      return [snakeKey, value]
    }),
  ) as CamelCaseToSnakeCase<T>
}
