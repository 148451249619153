import { isNotNil } from "./typeguards"
import { Maybe } from "./types"

export function safeParseInt(val: unknown): number | undefined {
  if (typeof val === "number") {
    return val
  }

  if (typeof val === "string") {
    const parsed = Number.parseInt(val)
    return Number.isNaN(parsed) ? undefined : parsed
  }

  return
}

export function safeParseFloat(val: unknown): number | undefined {
  if (typeof val === "number") {
    return val
  }

  if (typeof val === "string") {
    // https://stackoverflow.com/a/7571599
    const cleanedVal = val.replace(",", ".")
    const parsed = Number.parseFloat(cleanedVal)
    return Number.isNaN(parsed) ? undefined : parsed
  }

  return
}

export function safeParseString(val: unknown): string | undefined {
  if (typeof val === "string") {
    return val.trim() || undefined
  }

  if (typeof val === "number" || typeof val === "bigint" || typeof val === "boolean") {
    return val.toString()
  }
}

export function fibonacci(num: number): number {
  // https://betterprogramming.pub/fibonacci-algorithm-in-javascript-45743f3a0ff6
  return Math.round((Math.pow((1 + Math.sqrt(5)) / 2, num) - Math.pow((1 - Math.sqrt(5)) / 2, num)) / Math.sqrt(5))
}

export function randomFloat(min: number, max: number): number {
  return Math.random() * (max - min) + min
}

// https://stackoverflow.com/a/7228322/10324135
export function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function roundToNearest(number: number, multiple: number) {
  const half = multiple / 2
  return number + half - ((number + half) % multiple)
}

export function isWithinRange(value: Maybe<number>, min: number, max: number): value is number {
  return isNotNil(value) && value >= min && value <= max
}
