import { isNil, isNotNil } from "./typeguards"
import { Maybe } from "./types"

export function formatCurrencySymbol(locale: string, currency: string) {
  // currently Intl.DisplayNames does not return correct values in chrome for currency symbols (ceska koruna instead of Kc)
  // TODO: check if this is fixed and use Intl.DisplayNames instead with
  // return new Intl.DisplayNames(locale, { type: "currency", style: "narrow" }).of(currency)

  return new Intl.NumberFormat(locale, { style: "currency", currency })
    .format(0)
    .replace(/[\d,.]/g, "")
    .trim()
}

export function formatPercent(value: number, params?: { digits: number; locale?: string }): string {
  const { digits = 2, locale = "cs" } = params ?? {}
  return new Intl.NumberFormat(locale, { style: "percent", maximumFractionDigits: digits }).format(value)
}

export function formatCurrency(
  value: number,
  params?: { currency?: string; digits?: number; locale?: string },
): string {
  const { currency = "CZK", digits = 2, locale = "cs" } = params ?? {}
  if (Math.abs(value) < 0.005) {
    value = 0
  }

  return new Intl.NumberFormat(locale, { style: "currency", maximumFractionDigits: digits, currency }).format(value)
}

export function formatNumber(value: number, params?: { digits?: number; locale?: string }): string {
  const { digits = 2, locale = "cs" } = params ?? {}
  return new Intl.NumberFormat(locale, { maximumFractionDigits: digits, minimumFractionDigits: digits }).format(value)
}

export function formatRelativeTime(seconds: number, params?: { locale?: string }): string {
  const { locale = "cs" } = params ?? {}
  return new Intl.RelativeTimeFormat(locale, { style: "narrow" }).format(seconds, "seconds")
}

export function formatDuration(durationInSeconds: number): string {
  const hours = Math.floor(durationInSeconds / 3600)
  const minutes = Math.floor((durationInSeconds % 3600) / 60)
  const seconds = durationInSeconds % 60
  return `${hours}h ${minutes}m ${seconds}s`
}

export function formatSIUnitPrefixes(value: number) {
  const prefixName = [
    "quecto",
    "ronto",
    "yocto",
    "zepto",
    "atto",
    "femto",
    "pico",
    "nano",
    "micro",
    "milli",
    "",
    "kilo",
    "mega",
    "giga",
    "tera",
    "peta",
    "exa",
    "zetta",
    "yotta",
    "ronna",
    "quetta",
  ]

  const prefixSymbol = [
    "q",
    "r",
    "y",
    "z",
    "a",
    "f",
    "p",
    "n",
    "μ",
    "m",
    "",
    "k",
    "M",
    "G",
    "T",
    "P",
    "E",
    "Z",
    "Y",
    "R",
    "Q",
  ]

  let magnitude = Math.ceil(Math.log10(Math.abs(value)))

  // Set the magnitude to zero for log10(0), which returns -Infinity
  if (!Number.isFinite(magnitude)) {
    magnitude = 0
  }

  // Calculate prefix array index
  let index = Math.floor(magnitude / 3) + 10

  // Clamp index to lowest / highest prefix
  index = Math.min(20, Math.max(0, index))

  // Exponent to scale the value by
  const exponent = (index - 10) * 3

  const prefix = prefixName[index]
  const symbol = prefixSymbol[index]

  return { exponent, prefix, symbol, value: value / 10 ** exponent }
}

export function formatPower(input: number, params?: { digits?: number; locale?: string }) {
  const { digits = 2, locale = "cs" } = params ?? {}
  const { symbol, value } = formatSIUnitPrefixes(input)
  return `${formatNumber(value, { digits, locale })} ${symbol}W`
}

export function formatPowerWithUnit(input: number, params?: { digits?: number; locale?: string; unit?: number }) {
  const { digits = 2, locale = "cs", unit = 10 ** 3 } = params ?? {}
  const { symbol } = formatSIUnitPrefixes(unit)
  return `${formatNumber(input, { digits, locale })} ${symbol}W`
}

export function formatOptionalPower(input: Maybe<number>, params?: { digits?: number; locale?: string }) {
  const { digits = 2, locale = "cs" } = params ?? {}
  return isNotNil(input) ? formatPower(input, { digits, locale }) : "-- W"
}

export function formatPowerToKiloWatt(inputInWatt: number, params?: { digits?: number; locale?: string }) {
  const { digits = 2, locale = "cs" } = params ?? {}
  return `${formatNumber(inputInWatt / 1000, { digits, locale })} kW`
}

export function formatEnergy(input: number, params?: { digits?: number; locale?: string }) {
  const { digits = 2, locale = "cs" } = params ?? {}
  const { symbol, value } = formatSIUnitPrefixes(input)
  return `${formatNumber(value, { digits, locale })} ${symbol}Wh`
}

export function formatEnergyToKiloWattHour(inputInWattHours: number, params?: { digits?: number; locale?: string }) {
  const { digits = 2, locale = "cs" } = params ?? {}
  return `${formatNumber(inputInWattHours / 1000, { digits, locale })} kWh`
}

export function formatPricePerEnergy(
  input: Maybe<number>,
  params?: { currency?: string; digits?: number; locale?: string },
) {
  const { currency = "CZK", digits = 2, locale = "cs" } = params ?? {}
  if (isNil(input)) {
    return `-- ${formatCurrencySymbol(locale, currency)}/kWh`
  }
  return `${formatCurrency(input, { currency, digits, locale })}/kWh`
}

export function formatBytes(input: number, params?: { digits?: number; locale?: string }) {
  const { digits = 2, locale = "cs" } = params ?? {}
  const { symbol, value } = formatSIUnitPrefixes(input)
  return `${formatNumber(value, { digits, locale })} ${symbol}B`
}

export function formatEnergyUnit(order: number) {
  const { symbol } = formatSIUnitPrefixes(order)
  return `${symbol}Wh`
}

export function formatPricePerEnergyUnit(params?: { currency?: string; locale?: string }) {
  const { currency = "CZK", locale = "cs" } = params ?? {}

  return `${formatCurrencySymbol(locale, currency)}/kWh`
}

/**
 * Helper function that makes a formatter first argument optional.
 * @param formatter - formatter function
 * @param defaultValue - default value to return if the value is nil
 */
export function optionalFormatter<T, T2 extends Array<unknown>>(
  formatter: (value: T, ...args: T2) => string,
  defaultValue: string = "-",
): (value: Maybe<T>, ...args: T2) => string {
  return (value: Maybe<T>, ...args: T2) => {
    if (isNil(value)) {
      return defaultValue
    }
    return formatter(value, ...args)
  }
}
