export function isString(val: unknown): val is string {
  return typeof val === "string"
}

export function isObject(x: unknown): x is object {
  return typeof x === "object" && !Array.isArray(x) && x !== null
}

export function isNil<T>(val: T | null | undefined): val is null | undefined {
  return val === undefined || val === null
}

export function isNotNil<T>(val: T | null | undefined): val is T {
  return !isNil(val)
}

type FalsyValue = false | 0 | "" | null | undefined

export function isFalsy<T>(val: T | FalsyValue): val is FalsyValue {
  return !val
}

export function isNotFalsy<T>(val: T | FalsyValue): val is T {
  return !isFalsy(val)
}

export function assertNonNil<T>(
  input: T | undefined | null,
  msg?: string,
  ErrorConstructor?: new (message: string) => Error,
): asserts input is T {
  if (isNotNil(input)) {
    return
  }

  if (ErrorConstructor) {
    throw new ErrorConstructor(msg ?? "Provided value is nil.")
  }

  throw new Error(msg ?? "Provided value is nil.")
}

export function assertNil<T>(
  input: T | undefined | null,
  msg?: string,
  ErrorConstructor?: new (message: string) => Error,
): asserts input is undefined | null {
  if (isNil(input)) {
    return
  }

  if (ErrorConstructor) {
    throw new ErrorConstructor(msg ?? "Provided value is not nil.")
  }

  throw new Error(msg ?? "Provided value is not nil.")
}

export function assertString(
  input: unknown,
  msg?: string,
  ErrorConstructor?: new (message: string) => Error,
): asserts input is string {
  if (isString(input)) {
    return
  }

  if (ErrorConstructor) {
    throw new ErrorConstructor(msg ?? "Provided value is not a string.")
  }

  throw new Error(msg ?? "Provided value is not a string.")
}

export function assertUnreachable<T>(arg: never, ErrorConstructor?: new (args: T) => Error, errorArgs?: T): never {
  if (ErrorConstructor && errorArgs) {
    throw new ErrorConstructor(errorArgs)
  }
  throw new Error(`Didn't expect arg [${arg}] to get here`)
}

// Throw an error if the condition fails
// Strip out error messages for production
// > Not providing an inline default argument for message as the result is smaller
export function invariant(
  condition: unknown,
  // Can provide a string, or a function that returns a string for cases where
  // the message takes a fair amount of effort to compute
  message?: string | (() => string),
): asserts condition {
  if (condition) {
    return
  }

  const prefix = "Invariant failed"
  const providedMessage: string | undefined = typeof message === "function" ? message() : message
  const value: string = providedMessage ? `${prefix}: ${providedMessage}` : prefix
  throw new Error(value)
}
