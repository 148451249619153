import Decimal from "decimal.js"

import { isNil, isNotNil } from "./typeguards"
import { Maybe } from "./types"

// TODO test
export function convertWattToKilowatt(watt: Maybe<number | Decimal>): number | undefined {
  return isNotNil(watt) ? new Decimal(watt).div(1000).toNumber() : undefined
}

export function convertMWToW(mw: null | undefined): undefined
export function convertMWToW(mw: number | Decimal): number
export function convertMWToW(mw: Maybe<number | Decimal>): number | undefined {
  if (isNil(mw)) {
    return undefined
  }

  return new Decimal(mw).times(1000).times(1000).toNumber()
}

export function convertWToMW(mw: null | undefined): undefined
export function convertWToMW(mw: number | Decimal): number
export function convertWToMW(mw: Maybe<number | Decimal>): number | undefined {
  if (isNil(mw)) {
    return undefined
  }

  return new Decimal(mw).div(1000).div(1000).toNumber()
}
