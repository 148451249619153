import Decimal from "decimal.js"

import { isNil } from "./typeguards"

export type Primitive = string | number | symbol | bigint | boolean | null | undefined

/* eslint-disable @typescript-eslint/no-explicit-any */
export type Maybe<T> = T | undefined | null

export type ObjectValues<T> = T[keyof T]

type Wrap<T> = { [K in keyof T]-?: [T[K]] }
type Unwrap<T> = { [K in keyof T]: Extract<T[K], [any]>[0] }

export type PopParameter<F extends (...args: any) => any> =
  Wrap<Parameters<F>> extends [...infer InitPs, any] ? Unwrap<InitPs> : never

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type Tail<T extends any[]> = T extends [infer A, ...infer R] ? R : never

export type AnyFunction = (...args: any[]) => any

interface StandardEnum<T> {
  [id: string]: T | string
  [nu: number]: string
}

/**
 * Converts the given representation of the value of one enumerated constant to an equivalent enumerated type.
 *
 * @param type - An enumeration type
 * @param value - A value to convert
 */
export const genericValueToEnum = <T, K extends StandardEnum<T>>(
  type: StandardEnum<T>,
  value: Maybe<K[keyof K]>,
): T | undefined => {
  const keys = Object.keys(type) // ...but, not really.
  const values = Object.values(type)
    // Filter enum member names because `Object.values()` includes them.
    .filter((value) => !(typeof value === "string" && keys.includes(value) && type[value] !== value))

  if (isNil(value)) {
    return undefined
  }

  return values.includes(value) ? (value as unknown as T) : undefined
}

declare const $NestedValue: unique symbol
/**
 * @deprecated to be removed in the next major version
 */
export type NestedValue<TValue extends object = object> = {
  [$NestedValue]: never
} & TValue

export type BrowserNativeObject = Date | FileList | File

export type NullPartial<T> = {
  [K in keyof T]?: Maybe<NullPartial<T[K]>>
}

export type DeepNullPartial<T> = T extends BrowserNativeObject | NestedValue
  ? T
  : {
      [K in keyof T]?: Maybe<DeepNullPartial<T[K]>>
    }

// Analogues to array.prototype.shift
type Shift<T extends any[]> = ((...t: T) => any) extends (first: any, ...rest: infer Rest) => any ? Rest : never

// use a distributed conditional type here
type ShiftUnion<T> = T extends any[] ? Shift<T> : never

export type DeepRequired<T, P extends string[]> = T extends object
  ? Omit<T, Extract<keyof T, P[0]>> &
      Required<{
        [K in Extract<keyof T, P[0]>]: NonNullable<DeepRequired<T[K], ShiftUnion<P>>>
      }>
  : T

export type Optional<T, K extends keyof T> = Omit<T, K> & { [P in keyof T]?: T[P] | undefined }

export type StrictOmit<T, K extends keyof T> = Omit<T, K>

export type StrictPick<T, K extends keyof T> = Pick<T, K>

//
export type RequiredNotNull<TObject> = {
  // without -? this does not work https://github.com/microsoft/TypeScript/issues/28374#issuecomment-533103750
  [TKey in keyof TObject]-?: NonNullable<TObject[TKey]>
}

//
export type Ensure<T, K extends keyof T> = T & RequiredNotNull<Pick<T, K>>

export type SwapDecimalWithString<T> = {
  [k in keyof T]: T[k] extends Date
    ? T[k]
    : T[k] extends Maybe<Date>
      ? T[k]
      : T[k] extends Maybe<Decimal>
        ? string
        : T[k] extends Maybe<object>
          ? SwapDecimalWithString<T[k]>
          : T[k]
}

type SnakeCaseToPascalCase<S extends string> = S extends `${infer First}_${infer Rest}`
  ? `${Capitalize<First>}${SnakeCaseToPascalCase<Rest>}`
  : Capitalize<S>

export type SnakeCaseToCamelCase<T> = {
  [K in keyof T as K extends string
    ? K extends `${infer First}_${infer Rest}`
      ? `${First}${SnakeCaseToPascalCase<Rest>}`
      : K
    : K]: T[K]
}

export type CamelCaseToSnakeCase<T> = {
  [K in keyof T as K extends string ? FirstCharThenRest<K> : K]: T[K]
}

type FirstCharThenRest<S extends string> = S extends `${infer F}${infer R}`
  ? `${Lowercase<F>}${InnerCamelToSnakeCase<R>}`
  : S

type InnerCamelToSnakeCase<S extends string> = S extends `${infer F}${infer R}`
  ? F extends Uppercase<F>
    ? `_${Lowercase<F>}${InnerCamelToSnakeCase<R>}`
    : `${F}${InnerCamelToSnakeCase<R>}`
  : S
