import { objectEntries } from "./object"

interface ExpirableValue<T> {
  value: T
  lastUpdatedAt: number
}

type ExpirableObject<T> = Record<keyof T, ExpirableValue<T[keyof T]>>

export type ExpirableObjectRT<T extends Record<string, unknown>> = {
  upsert: (partialObject: T) => void
  get: () => Partial<T>
}

export function createExpirableObject<T extends Record<string, unknown>>(timeoutMs: number) {
  const store: Partial<ExpirableObject<T>> = {}

  function upsert(partialObject: T): void {
    const now = Date.now()

    objectEntries(partialObject).forEach(([k, value]) => {
      if (value === undefined) {
        return
      }

      store[k] = { value: value, lastUpdatedAt: now }
    })
  }

  function get(): Partial<T> {
    const now = Date.now()

    return Object.fromEntries(
      Object.entries(store)
        .filter(([, expirableObject]) => expirableObject && expirableObject.lastUpdatedAt + timeoutMs > now)
        .map(([key, expirableObject]) => [key, expirableObject?.value]),
    ) as Partial<T>
  }

  return { upsert, get }
}
